import { configureStore } from '@reduxjs/toolkit';
import checkoutReducer from './checkout';
import uiReducer from './ui';

export const store = configureStore({
    reducer: {
        ui: uiReducer,
        checkout: checkoutReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
