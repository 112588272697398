import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.side {
        justify-content: flex-end;
    }

    &.isOpen {
        width: 100vw;
        height: 100vh;
    }

    &.isClose {
        width: 0;
        height: 0;
    }
`;

export const Content = styled.div`
    background: white;

    &.center {
        max-width: 900px;
    }

    &.side {
        width: 400px;
    }

    &.isOpen {
        transform: translateX(0);
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
    }

    &.isClose {
        transform: translateX(100%);
        opacity: 0;
        transition: transform 1s, opacity 1s;
    }
`;

export const Label = styled.span``;

export const CloseButton = styled.button`
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${rem(20)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.h5};
`;

export const Body = styled.div`
    padding: ${rem(20)};
`;
