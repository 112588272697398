import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.span<{ isCircle: boolean; isFilterType: boolean; disabled: boolean }>`
    position: relative;
    margin-bottom: ${rem(15)};
    display: flex;
    align-items: center;

    input + label::before {
        ${(prop) => (prop.isFilterType ? `border: 2px solid ${prop.theme.colors.primary}` : 'border: 1px solid')};
        content: '';
        height: ${rem(20)};
        left: 0;
        position: absolute;
        top: 0;
        width: ${rem(20)};
        border-radius: 4px;
        background: ${(prop) => (prop.disabled ? prop.theme.colors.secondaryGrey : prop.theme.colors.white)};
        border-color: ${(prop) => (prop.disabled ? prop.theme.colors.secondaryGrey : prop.theme.colors.black)};
    }

    input + label::after {
        content: '';
        border: 2px solid;
        border-left: 0;
        border-top: 0;
        height: ${(prop) => (prop.isCircle ? rem(10) : rem(12))};
        left: ${rem(7)};
        opacity: 0;
        position: absolute;
        top: ${(prop) => (prop.isCircle ? rem(4) : rem(2))};
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
        width: ${(prop) => (prop.isCircle ? rem(6) : rem(7))};
        color: ${({ theme }) => theme.colors.white};
    }

    input:checked + label::before {
        background: ${(prop) => (prop.disabled ? prop.theme.colors.secondaryGrey : prop.theme.colors.primary)};
        border-color: ${(prop) => (prop.disabled ? prop.theme.colors.secondaryGrey : prop.theme.colors.primary)};
        ${(prop) => (prop.isCircle ? 'border-radius: 50%;' : '')};
    }

    input:checked + label::after {
        opacity: 1;
    }
`;

export const Input = styled.input`
    height: ${rem(20)};
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: ${rem(20)};
`;

export const Label = styled.label<{ isBold?: boolean }>`
    display: block;
    padding-left: ${rem(35)};
    cursor: pointer;
    font-weight: ${(prop) => (prop.isBold ? prop.theme.fontWeights.textBold : prop.theme.fontWeights.textRegular)};
`;
