import { rem } from 'polished';

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1300,
};

export const theme = {
    colors: {
        white: '#FFF',
        black: '#151515',
        primary: '#B30F10',
        primaryLight: '#DDF2F8',
        primaryMedium: '#A2E6FA',
        grey: '#616366',
        dark: '#747881',
        greyLight: '#F5FBFD',
        greyMedium: '#F3F3F3',
        darkLight: '#F5F6F8',
        secondaryGrey: '#74788140',
        green: '#0A730F',
        red: '#BB2F2D',
        danger: '#FF0000',
        border: 'rgba(116, 120, 129, 0.25)',
        conferencePrimary: '#00558B',
        conferencePrimaryLight: '#AEC5DA',
        conferenceGrayLight: '#c8c6c7',
        conferenceGrayDark: '#6f6f6f',
        conferencePPmain: '#00558B',
        conferencePPlight: '#e6f4fd',
        conferenceEPImain: '#B30F10',
        conferenceEPIlight: '#f7e5e5',
        conferenceDNAmain: '#7A006D',
        conferenceDNAsuppl: '#F68B0E',
        conferenceDNAlight: '#fde2c4',
        conferenceDNAlightest: '#fef5ec',
        conferenceCSmain: '#00447a',
        conferenceCSsuppl: '#d4af37',
        conferenceCSlight: '#f4ebce',
        conferenceCSlightest: '#fcf9f0',
    },
    fonts: {
        fontBase: 'Open Sans, sans-serif',
        fontSecondary: 'Montserrat, sans-serif',
    },
    fontWeights: {
        textLight: 300,
        textRegular: 400,
        textBold: 700,
    },
    fontSizes: {
        textTiny: rem('10px'),
        textSmall: rem('12px'),
        textBase: rem('14px'),
        textMedium: rem('16px'),
        h1: rem('70px'),
        h2: rem('42px'),
        h3: rem('32px'),
        h4: rem('26px'),
        h5: rem('21px'),
        h6: rem('18px'),
    },
    transitionDelay: {
        standard: '300ms',
    },
};

export interface SC {
    className?: string;
    children?: any;
    style?: any;
}
