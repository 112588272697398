import React from 'react';
import { FC } from 'react';
import { Spinner } from './Spinner.elements';

export interface SpinnerProps {
    size?: number;
}

const SpinnerTemplate: FC<SpinnerProps> = ({ size }) => {
    return <Spinner size={size} />;
};

export default SpinnerTemplate;
