import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.label<{ disabledForChange: boolean }>`
    cursor: pointer;
    display: inline-block;
    opacity: ${(props) => (props.disabledForChange ? 0.4 : 1)};
`;

export const Label = styled.span<{ isChecked: boolean }>`
    margin-right: ${rem(30)};
    position: relative;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.white};
`;

export const Toggle = styled.input`
    position: absolute;
    visibility: hidden;
    &:checked + .toggle-switch {
        background: ${({ theme }) => theme.colors.primary};
    }

    &:checked + .toggle-switch:before {
        left: ${rem(26)};
    }
`;

export const Switch = styled.div`
    display: inline-block;
    background: #ccc;
    border-radius: ${rem(16)};
    width: ${rem(48)};
    height: ${rem(24)};
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;

    &:before,
    &:after {
        content: '';
    }

    &:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border};
        width: ${rem(18)};
        height: ${rem(18)};
        position: absolute;
        top: ${rem(3)};
        left: ${rem(4)};
        transition: left 0.25s;
    }
`;
