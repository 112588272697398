import { rem } from 'polished';
import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{ size: number }>`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);

    border-top: 2px solid ${({ theme }) => theme.colors.greyLight};
    border-right: 2px solid ${({ theme }) => theme.colors.greyLight};
    border-bottom: 2px solid ${({ theme }) => theme.colors.greyLight};
    border-left: 4px solid ${({ theme }) => theme.colors.primary};
    background: transparent;
    width: ${(prop) => (prop.size ? rem(prop.size) : rem(24))};
    height: ${(prop) => (prop.size ? rem(prop.size) : rem(24))};
    border-radius: 50%;
    margin: 0 auto;
`;
