import jwt_decode from 'jwt-decode';
import { authApi } from '../services/auth.service';
import { store } from '../store';
import { setIsLoggedIn } from '../store/ui';

export const isValidAndRefresh = async (accessTokenValue: string, refreshTokenValue: string) => {
    if (accessTokenValue && accessTokenValue != 'null') {
        const decoded: { exp: number } = jwt_decode(accessTokenValue);

        if (new Date(decoded.exp * 1000) > new Date()) {
            return { accessToken: accessTokenValue, refreshToken: refreshTokenValue };
        } else {
            return await refreshToken(refreshTokenValue);
        }
    } else if (refreshTokenValue && refreshTokenValue !== 'null') {
        return await refreshToken(refreshTokenValue);
    }

    return { accessToken: null, refreshToken: null };
};

export const isValidWithoutRefresh = (accessTokenValue: string) => {
    const decoded: { exp: number } = jwt_decode(accessTokenValue);

    return new Date(decoded.exp * 1000) > new Date();
};

const refreshToken = async (refreshToken: string) => {
    try {
        const { data } = await authApi.refresh(refreshToken);

        store.dispatch?.(setIsLoggedIn(true));

        return { accessToken: data.access_token, refreshToken: data.refresh_token };
    } catch {
        store.dispatch?.(setIsLoggedIn(false));

        return { accessToken: null, refreshToken: null };
    }
};
