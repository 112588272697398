import React, { useRef, useState } from 'react';
import { FC } from 'react';
import { Icons } from '@components/Icon';
import useTranslations from '@hooks/useTranslation';
import { EMAIL_REGEX } from '../../../constants';
import { authApi } from '../../../services/auth.service';
import ButtonTemplate from '../../../ui/Button/Button';
import { Description, Error, Input, Label, SuccessIcon, SuccessMessage, Wrapper } from './PasswordModal.elements';

export interface PasswordProps {}

const PasswordModalTemplate: FC<PasswordProps> = () => {
    const { t } = useTranslations();

    const emailInputRef = useRef(null);

    const [hasEmailError, setHasEmailError] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = async () => {
        setHasEmailError(false);

        if (emailInputRef?.current?.value === '' || !EMAIL_REGEX.test(emailInputRef?.current?.value)) {
            setHasEmailError(true);

            return;
        }

        await authApi.forgotPassword(emailInputRef?.current?.value);

        setIsSent(true);
    };

    return (
        <Wrapper center={isSent}>
            {isSent ? (
                <>
                    <SuccessIcon name={Icons.Success} />
                    <SuccessMessage>
                        {t('modal.password.sent.success')}
                    </SuccessMessage>
                </>
            ) : (
                <>
                    <Description>{t('modal.password.description')}</Description>
                    <Label>
                        {t('modal.login.mail.label')}
                        {hasEmailError && <Error>{t('modal.login.mail.error')}</Error>}
                    </Label>
                    <Input
                        ref={emailInputRef}
                        className={hasEmailError ? 'error' : ''}
                        placeholder={t('modal.login.mail.placeholder')}
                    />
                    <ButtonTemplate onClick={handleSubmit} fullWidth text={t('modal.password.submit')} />
                </>
            )}
        </Wrapper>
    );
};

export default PasswordModalTemplate;
