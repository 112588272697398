import React, { ReactElement, useState } from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import SpinnerTemplate from '@ui/Spinner/Spinner';
import { RootState } from '../../store';
import { Background, Spinner } from './Loading.elements';

export interface LoadingProps {
    isRouterChange: boolean;
}

const LoadingTemplate: FC<LoadingProps> = ({ isRouterChange = false }) => {
    const isLoading = useSelector((state: RootState) => state.ui.isLoading);

    return (
        (isLoading || isRouterChange) && (
            <Background>
                <Spinner>
                    <SpinnerTemplate size={40} />
                </Spinner>
            </Background>
        )
    );
};

export default LoadingTemplate;
