import React, { FC } from 'react';
import { IconProps, Icons } from '.';
import { iconsShared } from './shared';

const getIcon = (name: Icons): string => {
    return iconsShared(name);
};

export const IconTemplate: FC<IconProps> = ({ name, className, onClick }) => {
    return (
        <span
            className={`${className} icon-wrapper`}
            dangerouslySetInnerHTML={{ __html: getIcon(name) }}
            onClick={onClick}
        />
    );
};
