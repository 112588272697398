import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { openModalType } from '../../../store/ui';
import { Button, Header, Title, Wrapper } from './CartAutoUpdate.elements';

export interface CartAutoUpdateProps {}

const CartAutoUpdateTemplate: FC<CartAutoUpdateProps> = () => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    return (
        <Wrapper>
            <Header>
                <Title>{t('modal.cart.sync.message')}</Title>
            </Header>
            <Button>
                <ButtonTemplate
                    marginTop={40}
                    onClick={() => dispatch(openModalType(null))}
                    text={t('modal.cart.sync.button')}
                />
            </Button>
        </Wrapper>
    );
};

export default CartAutoUpdateTemplate;
