import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import styled from 'styled-components';

export interface UIState {
    isLoading: boolean;
    openModal: {
        type: string;
        data?: {};
    };
    isLoggedIn: boolean;
    needToUpdateFavoriteList: boolean;
    error: {
        translationKey: string;
    };
}

const initialState: UIState = {
    isLoading: false,
    openModal: null,
    isLoggedIn: false,
    needToUpdateFavoriteList: false,
    error: {
        translationKey: null,
    },
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        openModalType: (state, action: PayloadAction<{ type: string; data?: {} }>) => {
            state.openModal = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setIsNeedToUpdateFavoriteList: (state, action: PayloadAction<boolean>) => {
            state.needToUpdateFavoriteList = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error.translationKey = action.payload;
        },
    },
});

export const { isLoading, openModalType, setIsLoggedIn, setIsNeedToUpdateFavoriteList, setError } = uiSlice.actions;

export default uiSlice.reducer;
