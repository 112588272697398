import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import useTranslations from '@hooks/useTranslation';
import SwitchTemplate from '@ui/Switch/Switch';
import Cookies from 'js-cookie';
import { COOKIES_KEY, ROUTES } from '../../constants';
import {
    Button,
    Buttons,
    CloseButton,
    CustomDescription,
    CustomRow,
    CustomTitle,
    Description,
    MoreLink,
    Switch,
    Title,
    Wrapper,
} from './Cookie.elements';

interface CookieProps {}

enum VERSION {
    DEFAULT = 'default',
    DECLINE = 'decline',
    CUSTOM = 'custom',
}

export enum COOKIE_SETTINGS {
    ACCEPT_ALL = 'accept_all',
    DISABLED_ALL = 'disabled_all',
    ACCEPT_NECESSARY = 'accept_necessary',
    ACCEPT_COMFORT = 'accept_comfort',
    ACCEPT_MARKETING = 'accept_marketing',
    CLOSED = 'closed_without_select',
}

const CookieTemplate: FC<CookieProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    const [activeVersion, setActiveVersion] = useState(VERSION.DEFAULT);

    const [acceptNecessary, setAcceptNecessary] = useState(true);
    const [acceptComfort, setAcceptComfort] = useState(true);
    const [acceptMarketing, setAcceptMarketing] = useState(true);

    useEffect(() => {
        const cookies = Cookies.get(COOKIES_KEY);

        if (cookies) {
            setAcceptMarketing(
                cookies.includes(COOKIE_SETTINGS.ACCEPT_MARKETING) || cookies.includes(COOKIE_SETTINGS.ACCEPT_ALL)
            );
            setAcceptComfort(
                cookies.includes(COOKIE_SETTINGS.ACCEPT_COMFORT) || cookies.includes(COOKIE_SETTINGS.ACCEPT_ALL)
            );
        }
    }, []);

    const handleDecline = async () => {
        Cookies.set(COOKIES_KEY, `${COOKIE_SETTINGS.DISABLED_ALL}`, { expires: 365 });
        delete router.query.updateCookies;
        await router.push({
            pathname: router.pathname,
            query: router.query,
        });
        router.reload();
    };

    const handleAcceptAll = async () => {
        Cookies.set(COOKIES_KEY, `${COOKIE_SETTINGS.ACCEPT_ALL}`, { expires: 365 });
        delete router.query.updateCookies;
        await router.push({
            pathname: router.pathname,
            query: router.query,
        });
        router.reload();
    };

    const handleAcceptCustom = async () => {
        Cookies.set(
            COOKIES_KEY,
            `${COOKIE_SETTINGS.ACCEPT_NECESSARY}${acceptComfort ? `,${COOKIE_SETTINGS.ACCEPT_COMFORT}` : ''}${
                acceptMarketing ? `,${COOKIE_SETTINGS.ACCEPT_MARKETING}` : ''
            }`,
            { expires: 365 }
        );
        delete router.query.updateCookies;
        await router.push({
            pathname: router.pathname,
            query: router.query,
        });
        router.reload();
    };

    return (
        <Wrapper id="cookie">
            {activeVersion === VERSION.DEFAULT && (
                <>
                    <Title>{t('cookie.default.title')}</Title>
                    <Description dangerouslySetInnerHTML={{ __html: t('cookie.default.description1') }} />
                    <Description dangerouslySetInnerHTML={{ __html: t('cookie.default.description2') }} />
                    <Description dangerouslySetInnerHTML={{ __html: t('cookie.default.description3') }} />
                    <MoreLink onClick={() => router.push({ pathname: ROUTES.COOKIES })}>
                        {t('cookie.default.link')}
                    </MoreLink>
                    <Buttons>
                        <Button onClick={() => setActiveVersion(VERSION.DECLINE)}>{t('cookie.default.button1')}</Button>
                        <Button onClick={() => setActiveVersion(VERSION.CUSTOM)}>{t('cookie.default.button2')}</Button>
                        <Button onClick={handleAcceptAll}>{t('cookie.default.button3')}</Button>
                    </Buttons>
                </>
            )}
            {activeVersion === VERSION.DECLINE && (
                <>
                    <Title>{t('cookie.decline.title')}</Title>
                    <Description>{t('cookie.decline.description')}</Description>
                    <Buttons>
                        <Button onClick={() => setActiveVersion(VERSION.DEFAULT)}>{t('cookie.decline.button1')}</Button>
                        <Button onClick={handleDecline}>{t('cookie.decline.button2')}</Button>
                    </Buttons>
                </>
            )}
            {activeVersion === VERSION.CUSTOM && (
                <>
                    <Title>{t('cookie.custom.title')}</Title>
                    <Description dangerouslySetInnerHTML={{ __html: t('cookie.custom.description') }} />
                    <CustomRow>
                        <CustomTitle>{t('cookie.necessary.title')}</CustomTitle>
                        <CustomDescription>{t('cookie.necessary.description')}</CustomDescription>
                        <Switch>
                            <SwitchTemplate
                                disabledForChange
                                name="necessary"
                                isChecked={acceptNecessary}
                                label={t('cookie.custom.allowed')}
                            />
                        </Switch>
                    </CustomRow>
                    <CustomRow>
                        <CustomTitle>{t('cookie.comfort.title')}</CustomTitle>
                        <CustomDescription>{t('cookie.comfort.description')}</CustomDescription>
                        <Switch>
                            <SwitchTemplate
                                name="comfort"
                                onChange={() => setAcceptComfort(!acceptComfort)}
                                isChecked={acceptComfort}
                                label={acceptComfort ? t('cookie.custom.allowed') : t('cookie.custom.disabled')}
                            />
                        </Switch>
                    </CustomRow>
                    <CustomRow>
                        <CustomTitle>{t('cookie.marketing.title')}</CustomTitle>
                        <CustomDescription>{t('cookie.marketing.description')}</CustomDescription>
                        <Switch>
                            <SwitchTemplate
                                name="marketing"
                                onChange={() => setAcceptMarketing(!acceptMarketing)}
                                isChecked={acceptMarketing}
                                label={acceptMarketing ? t('cookie.custom.allowed') : t('cookie.custom.disabled')}
                            />
                        </Switch>
                    </CustomRow>
                    <Buttons>
                        <Button onClick={() => setActiveVersion(VERSION.DEFAULT)}>{t('cookie.custom.button1')}</Button>
                        <Button onClick={handleAcceptCustom}>{t('cookie.custom.button2')}</Button>
                    </Buttons>
                </>
            )}
            <CloseButton
                onClick={() => {
                    const hasSetCookies = Cookies.get(COOKIES_KEY);

                    if (hasSetCookies) {
                        delete router.query.updateCookies;
                        return router.push({
                            pathname: router.pathname,
                            query: router.query,
                        });
                    }

                    document.getElementById('cookie').style.display = 'none';
                }}
            >
                &#10005;
            </CloseButton>
        </Wrapper>
    );
};

export default CookieTemplate;
