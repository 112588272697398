import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslations from '@hooks/useTranslation';
import { ROUTES } from '../../constants';
import { RootState } from '../../store';
import { setError } from '../../store/ui';
import { Close, ContactLink, Message, Wrapper } from './Alert.elements';

export interface AlertProps {
    type: AlertTypes;
    message?: string;
}

export enum AlertTypes {
    error = 'Error',
    warning = 'Warning',
    success = 'Success',
}

const AlertTemplate: FC<AlertProps> = ({ type, message }) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const error = useSelector((state: RootState) => state.ui.error);

    // is open when props has message or redux state has error
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const element = document.getElementById('alert');

        if (element) {
            element.style.bottom = `${window.scrollY > 0 ? `-${window.scrollY - 10}px` : '10px'}`;
        }
    }, [isOpen]);

    useEffect(() => {
        if (error?.translationKey) {
            setIsOpen(true);
        }
    }, [error]);

    useEffect(() => {
        if (message) {
            setIsOpen(true);
        }
    }, [message]);

    return isOpen ? (
        <Wrapper id="alert" type={type}>
            <Message>
                {message || t('alert.message.default')}{' '}
                {!message && <ContactLink href={ROUTES.CONTACT}>{t('alert.link.default')}</ContactLink>}
            </Message>
            <Close
                onClick={() => {
                    setIsOpen(false);
                    dispatch(setError(null));
                }}
            >
                &times;
            </Close>
        </Wrapper>
    ) : (
        <></>
    );
};

export default AlertTemplate;
