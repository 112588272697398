import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { ModalTypes } from '@components/Modal/Modal';
import { calculateCountOfItemsInCart } from '@utils/cart.utils';
import { isNumber } from 'lodash';
import { ROUTES } from '../constants';
import { DiscountCodeType } from '../interfaces/Cart';
import { cartApi } from '../services/cart.service';
import { orderApi } from '../services/order.service';
import { RootState } from '../store';
import {
    setBillingInformation,
    setBillingPaymentMethod,
    setContribution,
    setCountOfProductsInCart,
    setDiscountCode,
    setItems,
    setParticipants,
    setTotalPrices,
} from '../store/checkout';
import { openModalType, setError } from '../store/ui';

const NEW_CART_ID = 0;
export const CART_KEY_IN_STORAGE = 'cartId';

export function useCart() {
    const dispatch = useDispatch();

    const router = useRouter();

    const discountCode = useSelector((state: RootState) => state.checkout.discountCode);

    const addOrUpdateItemInCart = async (moduleId: string, quantity: number) => {
        try {
            const cartId = localStorage.getItem(CART_KEY_IN_STORAGE) || NEW_CART_ID;

            if (quantity >= 0) {
                const { data: cart } = router.pathname.includes(ROUTES.CART)
                    ? await cartApi.updateItem(cartId, moduleId, quantity)
                    : await cartApi.addItem(cartId, moduleId, quantity);

                const { data: prices } = await orderApi.calculatePrices(cart?.cart?.cart_id, null, discountCode?.code);

                localStorage.setItem(CART_KEY_IN_STORAGE, cart.cart.cart_id);

                if (
                    ![
                        ROUTES.CART,
                        ROUTES.SHOP_PARTICIPANTS,
                        ROUTES.SHOP_PAYMENT,
                        ROUTES.SHOP_BILLING,
                        ROUTES.SHOP_CONFIRMATION,
                    ].includes(router.pathname)
                ) {
                    dispatch(
                        openModalType({
                            type: ModalTypes.ADD_PRODUCT_TO_CART,
                            data: {
                                isSuccess: true,
                                product: {
                                    name: cart.added?.event?.title || cart.added?.module?.name,
                                    quantity,
                                    price: cart.added?.module?.price_with_vat,
                                },
                                cart: {
                                    countOfItems: calculateCountOfItemsInCart(cart.cart.items),
                                    sumOfItems: prices.totalWithVat,
                                },
                            },
                        })
                    );
                }
                dispatch(setCountOfProductsInCart(calculateCountOfItemsInCart(cart.cart.items)));

                if (prices?.discountCode) {
                    dispatch(
                        setDiscountCode({
                            code: prices.discountCode._key,
                            status: prices.status,
                            value: prices.discountValue,
                            moduleIds: prices?.discounts,
                            type: prices?.discount?.type,
                            ...(prices?.discount?.type === DiscountCodeType.PERCENTAGE && {
                                percentageValue: prices?.discount?.value,
                            }),
                        })
                    );
                }

                if (cart.donation) {
                    dispatch(
                        setContribution({
                            amount: cart.donation,
                        })
                    );
                }

                dispatch(
                    setTotalPrices({
                        totalPriceWithVat: prices.totalWithVat,
                        totalPriceWithoutVat: prices.totalWithoutVat,
                    })
                );

                dispatch(setItems(cart.cart.items));

                return cart.cart;
            }
        } catch {
            dispatch(
                openModalType({
                    type: ModalTypes.ADD_PRODUCT_TO_CART,
                    data: {
                        isSuccess: false,
                    },
                })
            );
        }
    };

    const deleteItemFromCart = async (moduleId: string) => {
        try {
            const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

            const { data: cart } = await cartApi.deleteItem(cartId, moduleId);

            dispatch(setCountOfProductsInCart(calculateCountOfItemsInCart(cart.items)));

            const { data: prices } = await orderApi.calculatePrices(cartId);

            if (prices?.discountCode) {
                dispatch(
                    setDiscountCode({
                        code: prices.discountCode._key,
                        status: prices.status,
                        value: prices.discountValue,
                        moduleIds: prices?.discounts,
                        type: prices?.discount?.type,
                        ...(prices?.discount?.type === DiscountCodeType.PERCENTAGE && {
                            percentageValue: prices?.discount?.value,
                        }),
                    })
                );
            }

            if (cart.donation) {
                dispatch(
                    setContribution({
                        amount: cart.donation,
                    })
                );
            }

            dispatch(
                setTotalPrices({
                    totalPriceWithVat: prices.totalWithVat,
                    totalPriceWithoutVat: prices.totalWithoutVat,
                })
            );

            dispatch(setItems(cart.items));

            localStorage.setItem(CART_KEY_IN_STORAGE, cart.cart_id);

            return cart;
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    const deleteItemsFromCart = async () => {
        try {
            const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

            const { data: cart } = await cartApi.deleteAllItems(cartId);

            dispatch(setCountOfProductsInCart(calculateCountOfItemsInCart(cart.items)));

            const { data: prices } = await orderApi.calculatePrices(cartId);

            if (prices?.discountCode) {
                dispatch(
                    setDiscountCode({
                        code: prices.discountCode._key,
                        status: prices.status,
                        value: prices.discountValue,
                        moduleIds: prices?.discounts,
                        type: prices?.discount?.type,
                        ...(prices?.discount?.type === DiscountCodeType.PERCENTAGE && {
                            percentageValue: prices?.discount?.value,
                        }),
                    })
                );
            }

            if (cart.donation) {
                dispatch(
                    setContribution({
                        amount: cart.donation,
                    })
                );
            }

            dispatch(
                setTotalPrices({
                    totalPriceWithVat: prices.totalWithVat,
                    totalPriceWithoutVat: prices.totalWithoutVat,
                })
            );

            dispatch(setItems(cart.items));

            localStorage.setItem(CART_KEY_IN_STORAGE, cart.cart_id);

            return cart;
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    const getItemsFromCart = async (withoutCalculatePrices?: boolean) => {
        const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

        try {
            if (cartId) {
                const { data: cart } = await cartApi.detail(cartId);

                dispatch(setCountOfProductsInCart(calculateCountOfItemsInCart(cart.items)));

                dispatch(setItems(cart.items));

                if (cart.personal_data) {
                    dispatch(
                        setBillingInformation({
                            person: {
                                firstName: cart.personal_data.first_name,
                                lastName: cart.personal_data.last_name,
                                email: cart.personal_data.email,
                                phone: cart.personal_data.phone,
                            },
                            invoice: {
                                address: cart.billing_information.street,
                                city: cart.billing_information.city,
                                postalCode: cart.billing_information.zip,
                                state: cart.billing_information.country,
                            },
                            company: {
                                name: cart.billing_information.company_name,
                                id: cart.billing_information.ico,
                                vatId: cart.billing_information.dic,
                                vatNo: cart.billing_information.ic_dph,
                            },
                            registerUser: cart?.registerUser,
                            password: cart?.password,
                        })
                    );
                }

                if (isNumber(cart.payment_type)) {
                    dispatch(setBillingPaymentMethod(String(cart.payment_type)));
                }

                if (cart.attendees?.length > 0) {
                    dispatch(setParticipants(cart.attendees));
                }

                if (!withoutCalculatePrices) {
                    const { data: prices } = await orderApi.calculatePrices(cartId, null,  discountCode?.code);

                    if (prices?.discountCode) {
                        dispatch(
                            setDiscountCode({
                                code: prices.discountCode._key,
                                status: prices.status,
                                value: prices.discountValue,
                                moduleIds: prices?.discounts,
                                type: prices?.discount?.type,
                                ...(prices?.discount?.type === DiscountCodeType.PERCENTAGE && {
                                    percentageValue: prices?.discount?.value,
                                }),
                            })
                        );
                    }

                    if (cart.donation) {
                        dispatch(
                            setContribution({
                                amount: cart.donation,
                            })
                        );
                    }

                    dispatch(
                        setTotalPrices({
                            totalPriceWithVat: prices.totalWithVat,
                            totalPriceWithoutVat: prices.totalWithoutVat,
                        })
                    );
                }

                if (cart.autoUpdated) {
                    dispatch(
                        openModalType({
                            type: ModalTypes.AUTO_UPDATED_CART,
                            data: {},
                        })
                    );
                }

                localStorage.setItem(CART_KEY_IN_STORAGE, cart.cart_id);

                return cart;
            }
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    const addOrDeleteDiscountCode = async (code: string) => {
        const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

        try {
            const { data: prices } = await orderApi.calculatePrices(cartId, null, code);

            dispatch(
                setDiscountCode({
                    code: prices?.discountCode?._key,
                    status: prices?.status,
                    value: prices?.discountValue || 0,
                    moduleIds: prices?.discounts,
                    type: prices?.discount?.type,
                    ...(prices?.discount?.type === DiscountCodeType.PERCENTAGE && {
                        percentageValue: prices?.discount?.value,
                    }),
                })
            );

            dispatch(
                setTotalPrices({
                    totalPriceWithVat: prices.totalWithVat,
                    totalPriceWithoutVat: prices.totalWithoutVat,
                })
            );

            return { status: prices?.status, value: prices?.discountValue || 0 };
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    const addOrDeleteDonation = async (contribution: number) => {
        const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

        try {
            if (cartId) {
                await cartApi.addDonation(cartId, contribution);

                const { data: prices } = await orderApi.calculatePrices(cartId, null, discountCode?.code);

                dispatch(
                    setDiscountCode({
                        code: prices?.discountCode?._key,
                        status: prices?.status,
                        value: prices?.discountValue || 0,
                        moduleIds: prices?.discounts,
                        type: prices?.discount?.type,
                        ...(prices?.discount?.type === DiscountCodeType.PERCENTAGE && {
                            percentageValue: prices?.discount?.value,
                        }),
                    })
                );

                dispatch(
                    setTotalPrices({
                        totalPriceWithVat: prices.totalWithVat,
                        totalPriceWithoutVat: prices.totalWithoutVat,
                    })
                );

                return prices;
            }
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    const saveUserDetails = async (
        personalData?: Object,
        billingInformation?: Object,
        paymentType?: number,
        attendees?: Object[],
        registerUser?: boolean,
        password?: string,
    ) => {
        const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

        try {
            if (cartId) {
                await cartApi.saveUserDetails(
                    cartId,
                    personalData,
                    billingInformation,
                    paymentType,
                    attendees,
                    registerUser,
                    password
                );
            }
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    const resetUserDetails = async () => {
        const cartId = localStorage.getItem(CART_KEY_IN_STORAGE);

        try {
            if (cartId) {
                await cartApi.resetUserDetails(cartId);
            }
        } catch {
            dispatch(setError('alert.message.default'));
        }
    };

    return {
        addOrUpdateItemInCart,
        deleteItemFromCart,
        deleteItemsFromCart,
        getItemsFromCart,
        addOrDeleteDiscountCode,
        addOrDeleteDonation,
        saveUserDetails,
        resetUserDetails,
    };
}

export default useCart;
