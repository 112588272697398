import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { getMaxPageWidth } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.black};
    z-index: 99999999;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    padding: ${rem(45)} ${rem(25)};
    max-height: 100%;
    overflow-y: scroll;

    @media (min-width: ${breakpoints.sm}px) {
        padding: ${rem(25)} ${rem(80)};
    }
`;

export const Title = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h4};
    margin-bottom: ${rem(10)};
`;

export const Description = styled.p`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const MoreLink = styled.a`
    background: transparent;
    text-decoration: unset;
    border: none;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    padding: 0;
    cursor: pointer;
    width: fit-content;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    margin: ${rem(10)} 0;
`;

export const Buttons = styled.div`
    text-align: center;
    margin-top: ${rem(18)};

    @media (min-width: ${breakpoints.sm}px) {
        text-align: right;
    }
`;

export const Button = styled.button`
    background: transparent;
    font-size: ${({ theme }) => theme.fontSizes.textMedium};
    color: ${({ theme }) => theme.colors.white};
    margin: ${rem(10)};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 24px;
    padding: ${rem(10)} ${rem(20)};
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.colors.primary};
    }

    @media (min-width: ${breakpoints.sm}px) {
        margin: ${rem(10)};
    }
`;

export const CloseButton = styled.button`
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    color: white;
    top: ${rem(20)};
    right: ${rem(20)};
    position: absolute;
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
`;

export const CustomRow = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    padding: ${rem(10)} 0;
    position: relative;
`;

export const CustomTitle = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
`;

export const CustomDescription = styled.p`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    margin: ${rem(5)} 0;

    @media (min-width: ${breakpoints.sm}px) {
        max-width: 80%;
    }
`;

export const Switch = styled.div`
    margin: ${rem(20)} 0 ${rem(10)} 0;

    @media (min-width: ${breakpoints.sm}px) {
        position: absolute;
        right: 0;
        top: 0;
    }
`;
