import Router from 'next/router';
import { isValidWithoutRefresh } from '@utils/auth.utils';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import https from 'https';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROUTES, getApiURL, getAuthApiURL } from '../constants';
import { store } from '../store';

let isAlreadyFetchingAccessToken = false;
let subscribers = [] as any[];

function onAccessTokenFetched(accessToken: string) {
    subscribers = subscribers.filter((callback) => callback(accessToken));
}

function addSubscriber(callback: any) {
    subscribers.push(callback);
}

class CoreAPIService {
    axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
        });

        this.axiosInstance.interceptors.request.use(
            async (config) => {
                // Server side access token is refreshing in _app.tsx file
                // Client side access token is refreshing here in file

                const originalRequest = config;

                let clientAccessToken = Cookies.get(ACCESS_TOKEN_KEY);

                if (
                    clientAccessToken &&
                    !isValidWithoutRefresh(clientAccessToken) &&
                    !config.url.includes('auth/token/refresh')
                ) {
                    try {
                        if (isAlreadyFetchingAccessToken) {
                            return new Promise((resolve) => {
                                addSubscriber((accessToken: string) => {
                                    originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                                    resolve(originalRequest);
                                });
                            });
                        }

                        isAlreadyFetchingAccessToken = true;
                        const { data } = await this.postAuth(
                            'auth/token/refresh',
                            { refresh_token: Cookies.get(REFRESH_TOKEN_KEY) },
                            { Authorization: `Bearer ${clientAccessToken}` }
                        );
                        Cookies.set(ACCESS_TOKEN_KEY, data.access_token);
                        Cookies.set(REFRESH_TOKEN_KEY, data.refresh_token, { expires: 3 });

                        clientAccessToken = data.access_token;
                        isAlreadyFetchingAccessToken = false;

                        if (subscribers?.length > 0) {
                            onAccessTokenFetched(data?.access_token);
                        }
                    } catch (error) {
                        Cookies.remove(ACCESS_TOKEN_KEY);
                        Cookies.remove(REFRESH_TOKEN_KEY);

                        isAlreadyFetchingAccessToken = false;

                        Router.push(ROUTES.LOGIN);
                    }
                }

                config.headers = {
                    ...config.headers,
                    ...(clientAccessToken && { Authorization: `Bearer ${clientAccessToken}` }),
                };

                return config;
            },
            (error) => Promise.reject(error)
        );
    }

    async getAuth<TResult = any>(path: string, params = {}, headers = {}): Promise<AxiosResponse<TResult>> {
        headers = {
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'get',
            url: `${getAuthApiURL()}/${path}`,
            headers,
            params,
        });
    }

    async postAuth(path: string, data: any, headers = {}, params: {} = {}): Promise<AxiosResponse<any>> {
        headers = {
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'post',
            url: `${getAuthApiURL()}/${path}`,
            data,
            headers,
            params,
        });
    }

    async patchAuth(path: string, data: any, headers = {}, params: {} = {}): Promise<AxiosResponse<any>> {
        headers = {
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'patch',
            url: `${getAuthApiURL()}/${path}`,
            data,
            headers,
            params,
        });
    }

    async get<TResult = any>(path: string, params = {}, token?: string): Promise<AxiosResponse<TResult>> {
        const headers = {
            ...(token && token !== 'null' && { Authorization: `Bearer ${token}` }),
        };
        return await this.axiosInstance.request({
            method: 'get',
            url: `${getApiURL()}/${path}`,
            headers,
            params,
        });
    }

    async put(path: string, data: any, params: {} = {}, token?: string): Promise<AxiosResponse<any>> {
        const headers = {
            ...(token && token !== 'null' && { Authorization: `Bearer ${token}` }),
        };
        return await this.axiosInstance.request({
            method: 'put',
            url: `${getApiURL()}/${path}`,
            data,
            headers,
            params,
        });
    }

    async post(path: string, data: any, params: {} = {}, token?: string): Promise<AxiosResponse<any>> {
        const headers = {
            ...(token && token !== 'null' && { Authorization: `Bearer ${token}` }),
        };
        return await this.axiosInstance.request({
            method: 'post',
            url: `${getApiURL()}/${path}`,
            data,
            headers,
            params,
        });
    }

    async delete(path: string, data?: any, params: {} = {}, token?: string): Promise<AxiosResponse<any>> {
        const headers = {
            ...(token && token !== 'null' && { Authorization: `Bearer ${token}` }),
        };
        return await this.axiosInstance.request({
            method: 'delete',
            url: `${getApiURL()}/${path}`,
            data,
            headers,
            params,
        });
    }
}

export default new CoreAPIService();
