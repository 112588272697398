import React from 'react';
import { FC } from 'react';
import { Label, Switch, Toggle, Wrapper } from './Switch.elements';

export interface SwitchProps {
    name: string;
    label: string;
    isChecked: boolean;
    onChange?: () => void;
    disabledForChange?: boolean;
}

const SwitchTemplate: FC<SwitchProps> = ({ name, label, isChecked, onChange, disabledForChange }) => {
    return (
        <Wrapper className="toggle" disabledForChange={disabledForChange}>
            <Label className="toggle-label" isChecked={isChecked}>
                {label}
            </Label>
            <Toggle
                className="toggle-checkbox"
                name={name}
                type="checkbox"
                onChange={() => onChange?.()}
                checked={isChecked}
            />
            <Switch className="toggle-switch"></Switch>
        </Wrapper>
    );
};

export default SwitchTemplate;
