import React, { useState } from 'react';
import { FC } from 'react';
import SpinnerTemplate from '@ui/Spinner/Spinner';
import { Button } from './Button.elements';

export enum ButtonSizes {
    SMALL = 'small',
    MEDIUM = 'medium',
}

export interface ButtonProps {
    text: string;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    blackType?: boolean;
    whiteType?: boolean;
    onClick?: Function;
    disabled?: boolean;
    fullWidth?: boolean;
    color?: string;
    isLoading?: boolean;
    size?: ButtonSizes;
    noWrap?: boolean;
}

const ButtonTemplate: FC<ButtonProps> = ({
    text,
    disabled = false,
    fullWidth = false,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    color,
    whiteType,
    blackType,
    onClick,
    isLoading = false,
    size,
    noWrap,
}) => {
    return (
        <Button
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            onClick={onClick as any}
            disabled={disabled}
            fullWidth={fullWidth}
            color={color}
            whiteType={whiteType}
            blackType={blackType}
            size={size}
            noWrap={noWrap}
        >
            {isLoading ? <SpinnerTemplate size={14} /> : text}
        </Button>
    );
};

export default ButtonTemplate;
