import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CartProductModalTemplate, { CartProductProps } from '@components/Modal/CartProduct/CartProductModal';
import LoginModalTemplate from '@components/Modal/Login/LoginModal';
import NewsletterModalTemplate from '@components/Modal/Newsletter/NewsletterModal';
import PasswordModalTemplate from '@components/Modal/Password/PasswordModal';
import useTranslations from '@hooks/useTranslation';
import { RootState } from '../../store';
import { openModalType } from '../../store/ui';
import { Body, CloseButton, Content, Header, Label, Wrapper } from './Modal.elements';
import CartAutoUpdateTemplate from "@components/Modal/CartAutoUpdate/CartAutoUpdate";

export interface ModalProps {}

export enum ModalTypes {
    LOGIN = 'login',
    PASSWORD = 'password',
    ADD_PRODUCT_TO_CART = 'add_product_to_cart',
    NEWSLETTER = 'newsletter',
    AUTO_UPDATED_CART = 'auto_updated_cart',
}

const CENTER_MODE_MODALS: string[] = [ModalTypes.ADD_PRODUCT_TO_CART, ModalTypes.NEWSLETTER, ModalTypes.AUTO_UPDATED_CART];

const ModalTemplate: FC<ModalProps> = () => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const modalType = useSelector((state: RootState) => state.ui.openModal?.type);
    const modalData = useSelector((state: RootState) => state.ui.openModal?.data);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(!!modalType);
    }, [modalType]);

    const getContext = () => {
        switch (modalType) {
            case ModalTypes.LOGIN:
                return <LoginModalTemplate />;
            case ModalTypes.ADD_PRODUCT_TO_CART:
                return <CartProductModalTemplate data={modalData as any} />;
            case ModalTypes.PASSWORD:
                return <PasswordModalTemplate />;
            case ModalTypes.NEWSLETTER:
                return <NewsletterModalTemplate />;
            case ModalTypes.AUTO_UPDATED_CART:
                return <CartAutoUpdateTemplate />;
        }
    };

    const getTitle = () => {
        switch (modalType) {
            case ModalTypes.LOGIN:
                return t('modal.login.title');
            case ModalTypes.PASSWORD:
                return t('modal.password.title');
        }
    };

    const handleClose = () => {
        dispatch(openModalType(null));
        setIsOpen(false);
    };

    return (
        <Wrapper
            className={`${isOpen ? 'isOpen' : 'isClose'} ${CENTER_MODE_MODALS.includes(modalType) ? 'center' : 'side'}`}
        >
            <Content
                className={`${isOpen ? 'isOpen' : 'isClose'} ${
                    CENTER_MODE_MODALS.includes(modalType) ? 'center' : 'side'
                }`}
            >
                {!CENTER_MODE_MODALS.includes(modalType) && isOpen && (
                    <Header>
                        <Label>{getTitle()}</Label>
                        <CloseButton onClick={handleClose}>&#10005;</CloseButton>
                    </Header>
                )}
                <Body>{getContext()}</Body>
            </Content>
        </Wrapper>
    );
};

export default ModalTemplate;
