import locales from '../../assets/public/locales/cz/common.json';

export function useTranslations() {
    const translate = (key: string, variables?: {}) => {
        let translatedStr = (locales as { [key: string]: string })[key] || key;

        if (variables && Object.keys(variables)?.length > 0) {
            Object.keys(variables)?.map((key) => {
                translatedStr = translatedStr.replace(
                    `{{${key}}}`,
                    String((variables as { [key: string]: string })[key])
                );
            });

            return translatedStr;
        }

        return translatedStr;
    };

    return { t: translate };
}

export default useTranslations;
