import { ROUTES, getBasePath } from '../constants';

export interface IPublicAssets {
    (input: string): string;
}

export const addBasePath = (path: string) => {
    const basePath = getBasePath();
    if (!basePath) {
        return path;
    }
    if (path === basePath) {
        return path;
    }
    if (path.indexOf(basePath + '/') === 0) {
        return path;
    }
    if (path.indexOf('/') !== 0 || path.indexOf('//') === 0) {
        return path;
    }
    return getBasePath() + path;
};

export const publicAssets: IPublicAssets = (path: string) => {
    if (path[0] != '/') {
        throw new Error('Path must start with "/" character.');
    }
    return addBasePath('/public' + path);
};

export const getNextPathnameByCurrent = (currentPathname: string) => {
    if (currentPathname.includes(ROUTES.SHOP_BILLING)) {
        return ROUTES.SHOP_PARTICIPANTS;
    }

    if (currentPathname.includes(ROUTES.SHOP_PARTICIPANTS)) {
        return ROUTES.SHOP_PAYMENT;
    }

    if (currentPathname.includes(ROUTES.SHOP_PAYMENT)) {
        return ROUTES.SHOP_SUMMARY;
    }

    if (currentPathname.includes(ROUTES.SHOP_SUMMARY)) {
        return ROUTES.SHOP_CONFIRMATION;
    }
};
