import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div<{ center?: boolean }>`
    ${(prop) => (prop.center ? 'text-align: center' : '')}};
`;

export const Label = styled.span`
    display: block;
    padding-bottom: ${rem(8)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    position: relative;
`;

export const Error = styled.span`
    color: ${({ theme }) => theme.colors.danger};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    position: absolute;
    left: 0;
    bottom: -${rem(60)};
`;

export const Description = styled.p`
    margin-top: -${rem(10)};
    margin-bottom: ${rem(25)};
`;

export const SuccessIcon = styled(IconTemplate)`
    svg {
        path {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const SuccessMessage = styled.p`
    text-align: center;
`;

export const Input = styled.input`
    width: 100%;
    height: ${rem(45)};
    border-radius: ${rem(4)};
    padding: ${rem(5)} ${rem(10)};
    border: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
    margin-bottom: ${rem(30)};

    &.error {
        border-color: ${({ theme }) => theme.colors.danger};
    }
`;
