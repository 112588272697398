import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';

export const Wrapper = styled.div<{ center?: boolean }>`
    ${(prop) => (prop.center ? 'text-align: center' : '')};
    padding: ${rem(40)};
    position: relative;
    max-width: ${rem(700)};
    height: 100vh;
    overflow-y: scroll;

    @media (min-width: ${breakpoints.sm}px) {
        padding: ${rem(40)};
        height: auto;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Title = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h3};
    font-family: ${({ theme }) => theme.fonts.fontSecondary};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    line-height: ${rem(40)};
    display: block;
    padding-bottom: ${rem(40)};
`;

export const CloseButton = styled.button`
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    position: absolute;
    top: ${rem(20)};
    right: ${rem(20)};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    color: ${({ theme }) => theme.colors.dark};
`;

export const Label = styled.span`
    display: block;
    padding-bottom: ${rem(30)};
    color: ${({ theme }) => theme.colors.grey};
    position: relative;
    line-height: ${rem(24)};
`;

export const Agreement = styled.div`
    a {
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
    }
`;

export const Areas = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${rem(10)};
`;

export const Area = styled.div`
    padding-bottom: ${rem(10)};
    width: 100%;

    @media (min-width: ${breakpoints.sm}px) {
        width: 50%;
    }
`;

export const Error = styled.span`
    color: ${({ theme }) => theme.colors.danger};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    display: block;
    margin-top: ${rem(-25)};
    margin-bottom: ${rem(25)};
`;

export const SuccessIcon = styled(IconTemplate)`
    svg {
        path {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const SuccessMessage = styled.p`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.h5};
    color: ${({ theme }) => theme.colors.grey};
`;

export const Input = styled.input`
    width: 100%;
    height: ${rem(45)};
    border-radius: ${rem(4)};
    padding: ${rem(5)} ${rem(10)};
    border: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
    margin-bottom: ${rem(30)};

    &.error {
        border-color: ${({ theme }) => theme.colors.danger};
    }
`;
