import Link from 'next/link';
import { AlertTypes } from '@components/Alert/Alert';
import { rem } from 'polished';
import styled from 'styled-components';

const getBackgroundByType = (prop: { type: AlertTypes; theme: { colors: { primaryLight: string } } }) => {
    if (prop.type === AlertTypes.error) return '#f7a7a3';
    if (prop.type === AlertTypes.success) return '#a8f0c6';

    return prop.theme.colors.primaryLight;
};

export const Wrapper = styled.div<{ type: AlertTypes }>`
    background: ${(prop) => getBackgroundByType(prop)};
    position: absolute;
    z-index: 999;
    max-width: ${rem(300)};
    padding: ${rem(15)} ${rem(45)} ${rem(15)} ${rem(10)};
    border-radius: ${rem(4)};
    left: ${rem(10)};
    bottom: ${rem(10)};
    border-left: 5px solid;
`;

export const Message = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const ContactLink = styled(Link)`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
`;

export const Close = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h4};
    position: absolute;
    top: 30%;
    right: 10px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.6;
`;
