import { isNumber } from 'lodash';
import coreApiService from './core.service';

const CART = 'eshop/cart';

export const cartApi = {
    detail: (id: string) => coreApiService.get(`${CART}/${id}`),
    addItem: (cartId: string | number, moduleId: string, quantity: number) =>
        coreApiService.put(`${CART}/${cartId}/item`, { module_id: moduleId, addQuantity: quantity }),
    updateItem: (cartId: string | number, moduleId: string, quantity: number) =>
        coreApiService.put(`${CART}/${cartId}/item`, { module_id: moduleId, updateQuantity: quantity }),
    deleteItem: (cartId: string | number, moduleId: string) =>
        coreApiService.delete(`${CART}/${cartId}/item/${moduleId}`),
    saveUserDetails: (
        cartId: string | number,
        personalData?: Object,
        billingInformation?: Object,
        paymentType?: number,
        attendees?: Object[],
        registerUser?: boolean,
        password?: string
    ) =>
        coreApiService.put(`${CART}/${cartId}/info`, {
            ...(personalData && { personal_data: personalData }),
            ...(billingInformation && { billing_information: billingInformation }),
            ...(isNumber(paymentType) && { payment_type: paymentType }),
            ...(attendees?.length >= 0 && { attendees }),
            ...(Boolean(registerUser) && { registerUser: true }),
            ...(password && { password }),
        }),
    resetUserDetails: (cartId: string | number) =>
        coreApiService.put(`${CART}/${cartId}/info`, {
            personal_data: null,
            billing_information: null,
            attendees: [],
        }),
    addDonation: (cartId: string, donation: number) => coreApiService.put(`${CART}/${cartId}/donation`, { donation }),
    deleteAllItems: (cartId: string | number) => coreApiService.delete(`${CART}/${cartId}/items`),
};
