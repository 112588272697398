import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';

export const Wrapper = styled.div<{ center?: boolean }>`
    padding: ${rem(20)};
    ${(prop) => (prop.center ? 'text-align: center' : '')}};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
    }
`;

export const Title = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h5};
`;

export const Buttons = styled.div`
    display: flex;
    gap: ${rem(20)};
    flex-direction: column-reverse;
    width: 100%;

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
        width: auto;
    }
`;

export const Button = styled.div`
    display: flex;
    justify-content: center;
`;
