import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Background = styled.div`
    opacity: 0.7;
    background: ${({ theme }) => theme.colors.white};
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999999;
    display: flex;
`;

export const Spinner = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
