import getConfig from 'next/config';

const config = getConfig();

const constants = {
    NEXT_PUBLIC_APP_PREFIX: config ? config.publicRuntimeConfig.basePath : '',
    NEXT_PUBLIC_HOST_API_URL: process.env.NEXT_PUBLIC_HOST_API_URL,
    NEXT_PUBLIC_AUTH_API_URL: process.env.NEXT_PUBLIC_AUTH_API_URL,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_ADS_URL: process.env.NEXT_PUBLIC_ADS_URL,
    NEXT_PUBLIC_SENTRY_DS: 'https://7391debbd6ad8dd3b3203e8f490b417c@sentry.prod.pp-holding.sk/7',
    NEXT_PUBLIC_CAPTCHA_KEY: process.env.NEXT_PUBLIC_CAPTCHA_KEY,
    NEXT_PUBLIC_CAPTCHA_SECRET: process.env.NEXT_PUBLIC_CAPTCHA_SECRET,
};

export const getBasePath = () => {
    return constants.NEXT_PUBLIC_APP_PREFIX;
};

export const getBaseUrl = () => {
    return 'https://studiow.cz';
};

export const getApiURL = () => {
    return constants.NEXT_PUBLIC_HOST_API_URL;
};

export const getAuthApiURL = () => {
    return constants.NEXT_PUBLIC_AUTH_API_URL;
};

export const getSentryDNS = () => {
    return constants.NEXT_PUBLIC_SENTRY_DS;
};

export const getCatalogUrl = () => {
    return 'https://wcm.prod.studiow.cz/media/static/catalog.pdf';
};

export const getImageBaseURL = () => {
    return constants.NEXT_PUBLIC_HOST_API_URL;
};

export const getAdsUrl = () => {
    return constants.NEXT_PUBLIC_ADS_URL;
};

export const getCaptchaKeys = () => {
    return {
        site: constants.NEXT_PUBLIC_CAPTCHA_KEY,
    };
};

export const getMaxPageWidth = () => {
    return 1180;
};

export const TRAININGS_LIMIT_PER_PAGE = 10;
export const CONFERENCES_LIMIT_PER_PAGE = 10;

export const MOBILE_HEADER_HEIGHT = 80;
export const DESKTOP_HEADER_HEIGHT = 130;

export const GLOBAL_SEARCH_COMPONENT_ID = 'search';
export const HEADER_COMPONENT_ID = 'header';

export const ACCESS_TOKEN_KEY = 'studiow_access_token';
export const REFRESH_TOKEN_KEY = 'studiow_refresh_token';
export const ACCESS_TOKEN_KEY_GUEST = 'studiow_guest_access_token';
export const REFRESH_TOKEN_KEY_GUEST = 'studiow_guest_refresh_token';
export const COOKIES_KEY = 'studiow_cookies';

export const IS_PROD = true;

export const EMAIL_REGEX: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX: RegExp = /^[+]?[/0-9. -]{9,}$/;

export const MINIMUM_PASSWORD_LENGTH: number = 8;

export enum FBQ_EVENTS {
    ADD_PAYMENT_INFO = 'AddPaymentInfo',
    ADD_TO_CART = 'AddToCart',
    CONTACT = 'Contact',
    LEAD = 'Lead',
    PURCHASE = 'Purchase',
    COMPLETE_REGISTRATION = 'CompleteRegistration',
}

export const ROUTES = {
    HOME: '/',
    CONTACT: '/kontakt',
    TRAININGS: '/kurzy',
    TRAINING: '/detail-kurzu',
    BLOG: '/blog',
    ARTICLE: '/clanek',
    CONFERENCES: '/konference',
    CLASSROOM_RENT: '/pronajem-uceben',
    PASSWORD_RESET: '/reset-hesla',
    COMPANY_COURSES: '/firemni-kurzy',
    CONFERENCE: '/konference',
    ABOUT_US: '/o-firme',
    ABOUT_US_REFERENCE: '/o-nas#referencie',
    CART: '/nakupni-kosik',
    SHOP_PARTICIPANTS: '/udaje-ucastniku',
    SHOP_BILLING: '/udaje-fakturacne',
    SHOP_PAYMENT: '/zpusob-platby',
    SHOP_SUMMARY: '/shrnuti-objednavky',
    SHOP_CONFIRMATION: '/potvrzeni-objednavky',
    ORDER: '/objednavka',
    SIGNIN: '/signin',
    REGISTER: '/registrace',
    GUEST: '/guest-login',
    ACTIVATION: '/aktivace-uctu',
    ACADEMY: '/akademie',
    ACADEMY_DETAIL: '/detail-akademie',
    NOT_FOUND: '/404',
    MY_PV: '/me-vzdelavani',
    MY_PV_COURSES: '/me-vzdelavani/kurzy',
    MY_PV_CONFERENCES: '/me-vzdelavani/konference',
    MY_PV_RECORDS: '/me-vzdelavani/zaznamy',
    MY_PV_FAVOURITES: '/me-vzdelavani/oblubene',
    MY_PV_MATERIALS: '/me-vzdelavani/materialy',
    MY_PV_NOTIFICATIONS: '/me-vzdelavani/notifikacie',
    HOME_MY_PV_COURSES: '/#skolenia',
    HOME_MY_PV_CONFERENCES: '/#konference',
    HOME_MY_PV_RECORDS: '/#zaznamy',
    HOME_MY_PV_FAVOURITES: '/#oblubene',
    HOME_MY_PV_MATERIALS: '/#materialy',
    LOGIN: '/prihlaseni',
    MY_TRAININGS: '/me-produkty',
    MY_ACCOUNT: '/muj-ucet',
    MY_ACCOUNT_USER: '/muj-ucet#pouzivatel',
    MY_ACCOUNT_PASSWORD: '/muj-ucet#heslo',
    MY_ACCOUNT_NOTIFICATIONS: '/muj-ucet#notifikacie',
    MY_ACCOUNT_BONUSES: '/muj-ucet#bonusy',
    MY_ACCOUNT_ACCESS: '/muj-ucet#pristupy',
    MY_ACCOUNT_ORDERS: '/muj-ucet#objednavky',
    LECTURER: '/lektor',
    SEARCH: '/vyhledavani',
    CONDITIONS: '/obchodni-podminky',
    GDPR: '/ochrana-osobnich-udaju',
    MARKETING: '/souhlas-s-maketingovou-komunikaci',
    COOKIES: '/souhlas-s-pouzivanim-cookies',
};
