import React from 'react';
import { FC } from 'react';
import { getImageBaseURL } from '../../constants';
import { Input, Label, Wrapper } from './Checkbox.elements';

export interface CheckboxProps {
    name?: string;
    checked: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    isFilterType?: boolean;
    isCircle?: boolean;
    disabled?: boolean;
}

const CheckboxTemplate: FC<CheckboxProps> = ({
    name,
    onChange,
    checked,
    children,
    isCircle = false,
    label,
    isFilterType = false,
    disabled = false,
}) => {
    return (
        <Wrapper isCircle={isCircle} isFilterType={isFilterType} disabled={disabled}>
            <Input id={`id-${name}`} onChange={onChange} checked={checked} name={name} type="checkbox" />
            <Label isBold={isFilterType && checked} htmlFor={`id-${name}`}>
                {children ? children : label}
            </Label>
        </Wrapper>
    );
};

export default CheckboxTemplate;
